export default {
  s3: {
    REGION: "us-west-2",
    BUCKET: "knox-app-uploads"
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://iadxenannl.execute-api.us-west-2.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_GiPXfgM2K",
    APP_CLIENT_ID: "5obajc68sihj7uaqtqd3910f42",
    IDENTITY_POOL_ID: "us-west-2:0128151e-522a-4465-8450-c4cdd18895db"
  }
};