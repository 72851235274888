import React from "react";
import { Route, Switch } from "react-router-dom";
import asyncComponent from "./components/AsyncComponent";
//import AppliedRoute from "./components/AppliedRoute";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import ResetPassword from "./containers/ResetPassword";
import Settings from "./containers/Settings";
import ChangePassword from "./containers/ChangePassword";
import ChangeEmail from "./containers/ChangeEmail";

const AsyncHome = asyncComponent(() => import("./containers/Home"));
const AsyncLogin = asyncComponent(() => import("./containers/Login"));
const AsyncSignup = asyncComponent(() => import("./containers/Signup"));
//Vendors
const AsyncVendors = asyncComponent(() => import("./containers/vendors/Vendors"));
const AsyncNewVendor = asyncComponent(() => import("./containers/vendors/NewVendor"));
const AsyncListVendors = asyncComponent(() => import("./containers/vendors/ListVendors"));
const AsyncEditVendor = asyncComponent(() => import("./containers/vendors/EditVendor"));
//Locations
const AsyncLocations = asyncComponent(() => import("./containers/locations/Locations"));
const AsyncNewLocation = asyncComponent(() => import("./containers/locations/NewLocation"));
const AsyncListLocations = asyncComponent(() => import("./containers/locations/ListLocations"));
const AsyncEditLocation = asyncComponent(() => import("./containers/locations/EditLocation"));
//Companies
const AsyncCompanies = asyncComponent(() => import("./containers/companies/Companies"));
const AsyncNewCompany = asyncComponent(() => import("./containers/companies/NewCompany"));
const AsyncListCompanies = asyncComponent(() => import("./containers/companies/ListCompanies"));
const AsyncEditCompany = asyncComponent(() => import("./containers/companies/EditCompany"));
//Credentials
const AsyncCredentials = asyncComponent(() => import("./containers/credentials/Credentials"));
const AsyncNewCredential = asyncComponent(() => import("./containers/credentials/NewCredential"));
const AsyncListCredentials = asyncComponent(() => import("./containers/credentials/ListCredentials"));
const AsyncEditCredential = asyncComponent(() => import("./containers/credentials/EditCredential"));
const AsyncChangePasswordCredential = asyncComponent(() => import("./containers/credentials/ChangePasswordCredential"));

//Credential Categories
const AsyncCredentialCategories = asyncComponent(() => import("./containers/credentialcategories/CredentialCategories"));
const AsyncNewCredentialCategory = asyncComponent(() => import("./containers/credentialcategories/NewCredentialCategory"));
const AsyncListCredentialCategories = asyncComponent(() => import("./containers/credentialcategories/ListCredentialCategories"));
const AsyncEditCredentialCategory = asyncComponent(() => import("./containers/credentialcategories/EditCredentialCategory"));

//Sites
const AsyncSites = asyncComponent(() => import("./containers/sites/Sites"));
const AsyncNewSite = asyncComponent(() => import("./containers/sites/NewSite"));
const AsyncListSites = asyncComponent(() => import("./containers/sites/ListSites"));
const AsyncEditSite = asyncComponent(() => import("./containers/sites/EditSite"));
//Peoples
const AsyncPeoples = asyncComponent(() => import("./containers/peoples/Peoples"));
const AsyncNewPeople = asyncComponent(() => import("./containers/peoples/NewPeople"));
const AsyncListPeoples = asyncComponent(() => import("./containers/peoples/ListPeoples"));
const AsyncEditPeople = asyncComponent(() => import("./containers/peoples/EditPeople"));
const AsyncEmailSignature = asyncComponent(() => import("./containers/peoples/EmailSignature"));
//Asset Categories
const AsyncAssetCategories = asyncComponent(() => import("./containers/assetcategories/AssetCategories"));
const AsyncNewAssetCategory = asyncComponent(() => import("./containers/assetcategories/NewAssetCategory"));
const AsyncListAssetCategories = asyncComponent(() => import("./containers/assetcategories/ListAssetCategories"));
const AsyncEditAssetCategory = asyncComponent(() => import("./containers/assetcategories/EditAssetCategory"));
//Assets
const AsyncAssets = asyncComponent(() => import("./containers/assets/Assets"));
const AsyncNewAsset = asyncComponent(() => import("./containers/assets/NewAsset"));
const AsyncListAssets = asyncComponent(() => import("./containers/assets/ListAssets"));
const AsyncEditAsset = asyncComponent(() => import("./containers/assets/EditAsset"));
const AsyncAssignAsset = asyncComponent(() => import("./containers/assets/AssignAsset"));
//NotFound
const AsyncNotFound = asyncComponent(() => import("./containers/NotFound"));




export default ({ childProps }) =>
  <Switch>
    <AuthenticatedRoute path="/" exact component={AsyncHome} props={childProps} />
    <UnauthenticatedRoute path="/login" exact component={AsyncLogin} props={childProps} />
    <UnauthenticatedRoute path="/signup" exact component={AsyncSignup} props={childProps} />
    <AuthenticatedRoute path="/companies" exact component={AsyncListCompanies} props={childProps} />
    <AuthenticatedRoute path="/companies/new" exact component={AsyncNewCompany} props={childProps} />
    <AuthenticatedRoute path="/companies/:id" exact component={AsyncCompanies} props={childProps} />
    <AuthenticatedRoute path="/companies/:id/edit" exact component={AsyncEditCompany} props={childProps} />
    <AuthenticatedRoute path="/credentials" exact component={AsyncListCredentials} props={childProps} />
    <AuthenticatedRoute path="/credentials/new" exact component={AsyncNewCredential} props={childProps} />
    <AuthenticatedRoute path="/credentials/:id" exact component={AsyncCredentials} props={childProps} />
    <AuthenticatedRoute path="/credentials/:id/edit" exact component={AsyncEditCredential} props={childProps} />
    <AuthenticatedRoute path="/credentials/:id/changepassword" exact component={AsyncChangePasswordCredential} props={childProps} />
    <AuthenticatedRoute path="/credentialcategories" exact component={AsyncListCredentialCategories} props={childProps} />
    <AuthenticatedRoute path="/credentialcategories/new" exact component={AsyncNewCredentialCategory} props={childProps} />
    <AuthenticatedRoute path="/credentialcategories/:id" exact component={AsyncCredentialCategories} props={childProps} />  
    <AuthenticatedRoute path="/credentialcategories/:id/edit" exact component={AsyncEditCredentialCategory} props={childProps} /> 
    <AuthenticatedRoute path="/locations" exact component={AsyncListLocations} props={childProps} />
    <AuthenticatedRoute path="/locations/new" exact component={AsyncNewLocation} props={childProps} />
    <AuthenticatedRoute path="/locations/:id" exact component={AsyncLocations} props={childProps} />
    <AuthenticatedRoute path="/locations/:id/edit" exact component={AsyncEditLocation} props={childProps} />
    <AuthenticatedRoute path="/vendors" exact component={AsyncListVendors} props={childProps} />
    <AuthenticatedRoute path="/vendors/new" exact component={AsyncNewVendor} props={childProps} />
    <AuthenticatedRoute path="/vendors/:id" exact component={AsyncVendors} props={childProps} />
    <AuthenticatedRoute path="/vendors/:id/edit" exact component={AsyncEditVendor} props={childProps} />
    <AuthenticatedRoute path="/sites" exact component={AsyncListSites} props={childProps} />
    <AuthenticatedRoute path="/sites/new" exact component={AsyncNewSite} props={childProps} />
    <AuthenticatedRoute path="/sites/:id" exact component={AsyncSites} props={childProps} /> 
    <AuthenticatedRoute path="/sites/:id/edit" exact component={AsyncEditSite} props={childProps} />
    <AuthenticatedRoute path="/assets" exact component={AsyncListAssets} props={childProps} />
    <AuthenticatedRoute path="/assets/new" exact component={AsyncNewAsset} props={childProps} />
    <AuthenticatedRoute path="/assets/:id" exact component={AsyncAssets} props={childProps} />  
    <AuthenticatedRoute path="/assets/:id/edit" exact component={AsyncEditAsset} props={childProps} />
    <AuthenticatedRoute path="/assets/:id/assign" exact component={AsyncAssignAsset} props={childProps} />
    <AuthenticatedRoute path="/assetcategories" exact component={AsyncListAssetCategories} props={childProps} />
    <AuthenticatedRoute path="/assetcategories/new" exact component={AsyncNewAssetCategory} props={childProps} />
    <AuthenticatedRoute path="/assetcategories/:id" exact component={AsyncAssetCategories} props={childProps} />  
    <AuthenticatedRoute path="/assetcategories/:id/edit" exact component={AsyncEditAssetCategory} props={childProps} /> 
    <AuthenticatedRoute path="/peoples" exact component={AsyncListPeoples} props={childProps} />
    <AuthenticatedRoute path="/peoples/new" exact component={AsyncNewPeople} props={childProps} />
    <AuthenticatedRoute path="/peoples/:id" exact component={AsyncPeoples} props={childProps} />   
    <AuthenticatedRoute path="/peoples/:id/edit" exact component={AsyncEditPeople} props={childProps} />
    <AuthenticatedRoute path="/peoples/:id/emailSignature" exact component={AsyncEmailSignature} props={childProps} />
    <UnauthenticatedRoute path="/login/reset" exact component={ResetPassword} props={childProps}/>
    <AuthenticatedRoute path="/settings" exact component={Settings} props={childProps}/>
    <AuthenticatedRoute path="/settings/password" exact component={ChangePassword} props={childProps}/>
    <AuthenticatedRoute path="/settings/email" exact component={ChangeEmail} props={childProps}/>

    
    { /* Finally, catch all unmatched routes */ }
    <Route component={AsyncNotFound} />
  </Switch>;